/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_RESPONSE = 'FETCH_NOTIFICATIONS_RESPONSE';

/* AUTH */
export const ROUTE_CHANGE = 'ROUTE_CHANGE';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const PROFILE_REGISTRATION_PENDING = 'PROFILE_REGISTRATION_PENDING';
export const REGISTER_PROFILE = 'REGISTER_PROFILE';
export const REGISTER_PROFILE_SUCCESS = 'REGISTER_PROFILE_SUCCESS';
export const REGISTER_PROFILE_ERROR = 'REGISTER_PROFILE_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const NEW_REGISTER = 'NEW_REGISTER';
export const NEW_REGISTER_SUCCESS = 'NEW_REGISTER_SUCCESS';
export const NEW_REGISTER_EMAIL_PASS_SUCCESS = 'NEW_REGISTER_EMAIL_PASS_SUCCESS';
export const NEW_REGISTER_ERROR = 'NEW_REGISTER_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';
export const CHANGE_SIDE_BAR_VISIBILITY = 'CHANGE_SIDE_BAR_VISIBILITY';

// SUBSCRIPTION
export const STORE_SUBSCRIPTION = 'STORE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const STORE_PHOTON = 'STORE_PHOTON';
export const TENANTS_DETAILS = 'TENANTS_DETAILS';
export const ROLE_TYPE = 'ROLE_TYPE';

export const STORE_CATEGORY = 'STORE_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
// STORIES
export const STORIES_TITLE = 'STORIES_TITLE';

// Chat
export const CHAT_BUTTON = 'CHAT_BUTTON';
export const CHAT_BUTTON_VISIBLE = 'CHAT_BUTTON_VISIBLE';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
// export * from './photon/action';
