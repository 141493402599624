/* eslint-disable no-else-return */
/* eslint-disable object-shorthand */
/* eslint-disable radix */
/* eslint-disable no-useless-escape */
/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
import { nanoid } from 'nanoid';
import React from 'react';
import axios from 'axios';
import { colord } from 'colord';
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey
} from '../constants/defaultValues';
import uploadFileToBlob from './Azure';
import IntlMessages from './IntlMessages';
import { NotificationManager } from 'components/common/react-notifications';
import { DateTime } from 'luxon';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const convertNumberToShort = (number) => {
  if (number > 1000) {
    const units = ['', 'K', 'M', 'B', 'T'];
    const unitIndex = Math.floor(Math.log10(Math.abs(number)) / 3);
    // eslint-disable-next-line no-restricted-properties
    const shortNumber = number / Math.pow(1000, unitIndex);
    const roundedNumber = Math.round(shortNumber * 10) / 10;
    return `${roundedNumber}${units[unitIndex]}`;
    // eslint-disable-next-line no-else-return
  } else {
    return number;
  }
};

export const generateColorArrays = (n) => {
  function generateRandomColor(minR, maxR, minG, maxG, minB, maxB) {
    const r = Math.floor(Math.random() * (maxR - minR + 1) + minR);
    const g = Math.floor(Math.random() * (maxG - minG + 1) + minG);
    const b = Math.floor(Math.random() * (maxB - minB + 1) + minB);
    return `rgb(${r}, ${g}, ${b})`;
  }

  function darkenColor(color, amount) {
    const rgb = color.replace(/[^\d,]/g, '').split(',');
    const r = Math.max(parseInt(rgb[0], 10) - amount, 0);
    const g = Math.max(parseInt(rgb[1], 10) - amount, 0);
    const b = Math.max(parseInt(rgb[2], 10) - amount, 0);
    return `rgb(${r}, ${g}, ${b})`;
  }

  const lightColors = [];
  const darkColors = [];

  for (let i = 0; i < n; i++) {
    // Generate a random RGB value for the light color
    const lightColor = generateRandomColor(200, 255, 200, 255, 200, 255);
    lightColors.push(lightColor);

    // Generate a darker shade of the light color
    const darkColor = darkenColor(lightColor, 50);
    darkColors.push(darkColor);
  }

  return {
    lightColors,
    darkColors
  };
};

export const generateShades = (color, numShades) => {
  const baseColor = color.slice(1); // Remove the "#" from the color string
  const r = parseInt(baseColor.substr(0, 2), 16);
  const g = parseInt(baseColor.substr(2, 2), 16);
  const b = parseInt(baseColor.substr(4, 2), 16);

  const shades = [];
  const step = Math.floor(255 / numShades); // Adjust this value for desired granularity

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < numShades; i++) {
    const shadeR = Math.max(0, r - i * step);
    const shadeG = Math.max(0, g - i * step);
    const shadeB = Math.max(0, b - i * step);

    const shade = `rgb(${shadeR}, ${shadeG}, ${shadeB})`;
    shades.push(shade);
  }

  return shades;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.error('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl'
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.error('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const topCountriesToBarData = (topCountriesData) => {
  const labels = [];
  const data = [];
  topCountriesData.forEach((entry) => {
    labels.push(entry.country);
    data.push(entry.media_impressions);
  });
  return { labels, data };
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.error(
      '>>>>: src/helpers/Utils.js : getCurrentColor -> error',
      error
    );
    currentColor = defaultColor;
  }
  return currentColor;
};

export const isDarkModeActive = () => {
  const color = localStorage.getItem(themeColorStorageKey);
  if (!color || color?.includes('light')) {
    return false;
  }
  return true;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.error(
      '>>>>: src/helpers/Utils.js : setCurrentColor -> error',
      error
    );
  }
};

export const isLoggedIn = !!JSON.parse(
  localStorage.getItem('innerloop_current_user')
);

export const checkifLoggedIn = () => {
  const user = localStorage.getItem('innerloop_current_user');
  const tenantDetails = localStorage.getItem('tenantDetails');
  return typeof user === 'string' && typeof tenantDetails === 'string';
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.error(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.error(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.error(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.error(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('innerloop_current_user') != null
        ? JSON.parse(localStorage.getItem('innerloop_current_user'))
        : null;
  } catch (error) {
    console.error(
      '>>>>: src/helpers/Utils.js  : getCurrentUser -> error',
      error
    );
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('innerloop_current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('innerloop_current_user');
    }
  } catch (error) {
    console.error(
      '>>>>: src/helpers/Utils.js : setCurrentUser -> error',
      error
    );
  }
};

export const valiateTenantName = (value) => {
  let error;
  if (!value) {
    error = 'Please enter your account name';
  } else if (/[^a-zA-Z0-9('.+') ]/.test(value)) {
    error = `Please enter a valid name having characters only`;
  } else if (value.trim().length < 3) {
    error = `Please enter a valid name having at least 3 characters`;
  } else if (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    error = `Please enter a valid name having at least 3 characters`;
  } else if (value.length > 40) {
    error = `Please enter a valid name with maximum 40 characters`;
  }
  return error;
};

export const validateInput = (InputName, value) => {
  let error;
  if (
    !value ||
    value === undefined ||
    (InputName === 'Data Region' && value === 'Select Your Data Region') ||
    (InputName === 'country' && value === 'Select Country')
  ) {
    error = `Please enter your ${InputName}`;
  } else if (InputName === 'name') {
    const regex = /[^a-zA-Z ]/.test(value);
    if (value.trim().length < 3) {
      error = `Please enter your Full Name`;
    } else if (value.length > 30) {
      error = `Please enter a valid Full Name, its looking too long`;
    } else if (regex) {
      error = `Please enter a valid name having characters only`;
    }
  }

  return error;
};

export const validatePassword = (value) => {
  let error;
  const passwordReg = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).+$');

  if (!value) {
    error = 'Please enter your password';
  } else if (value.length < 6 || !passwordReg.test(value)) {
    error =
      'Password must contain at least 6 characters,including UPPER/lowercase and numbers';
  } else {
    error = '';
  }
  return error;
};

export const validateAddress = (value) => {
  let error;
  if (!value || value.length < 10) {
    error = 'Please enter a valid address';
  } else {
    error = '';
  }
  return error;
};

export const validateLoginPassword = (value) => {
  let error;
  if (!value) {
    error = 'please enter your password';
  }
  return error;
};

export const validateEmail = (value, checkInnerloop) => {
  let error = '';
  const allowedHosts = process.env.REACT_APP_ALLOWED_DOMAINS.split(',');
  // eslint-disable-next-line no-useless-escape
  const emailRe =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!value) {
    error = 'Please enter your email address';
  } else if (!emailRe.test(value)) {
    error = `Please enter the valid email format (e.g. example@email.com)`;
  } else if (value.length > 5 && checkInnerloop) {
    const domainOfEmail = value?.split('@')[1];
    // eslint-disable-next-line array-callback-return
    const innerloopEmail = allowedHosts.some((u) => {
      if (u.includes(domainOfEmail)) {
        return true;
      }
    });
    if (innerloopEmail || domainOfEmail.includes('innerloop.')) {
      error = "Please don't use innerloop email address";
    }
  }
  return error;
};

export const validateUrl = (url, ourPlatformLink = true) => {
  let error;
  const allowedHosts = process.env.REACT_APP_ALLOWED_DOMAINS.split(',');
  const urlPattern =
    /^https:\/\/(www\.)?[a-zA-Z0-9-_.]+\.[a-zA-Z]{2,}\.?[\S]*$/;
  if (!url) {
    error = 'Please enter URL';
  } else if (!url.includes('https')) {
    error = 'Please use only HTTPS';
  } else if (!urlPattern.test(url)) {
    error = 'Invalid URL (use https://www.example.com)';
  } else if (url.length > 8 && ourPlatformLink) {
    const urlWithoutHttps = url.split('//')[1];
    const isUrlMatching = allowedHosts.some((u) => {
      return u.includes(urlWithoutHttps);
    });
    if (isUrlMatching || urlWithoutHttps.includes('innerloop.')) {
      error = 'Please do not use our platform link';
    }
  }

  return error;
};

export const confirmPasswordMatch = (value, checkValue) => {
  let error;
  if (value && checkValue) {
    if (value !== checkValue) {
      error = "Passwords don't match";
    }
  }
  return error;
};

export const validatePhone = (value) => {
  let error;
  const partten = /[4-9][0-9]{9}/;
  if (!partten.test(value)) {
    error = 'Please enter a valid phone number';
  }
  return error;
};

export const completedFirstTimeLogin = () => {
  localStorage.setItem('status', 'completed');
};

export const isFirstTimeLogin = () => {
  return !localStorage.getItem('status');
};

export const ToggleDarkMode = () => {
  const timeAndDate = new Date().toLocaleString();
  const timeObject = timeAndDate.split(',')[1];
  const timeNum = timeObject.split(':')[0];
  const meridian = timeObject.split(':')[2].split(' ')[1];
  if (meridian === 'AM') {
    if (timeNum < 7) {
      return true;
    }
  }
  if (meridian === 'PM') {
    if (timeNum > 6.9) {
      return true;
    }
  }
  return false;
};

export const isDarkMode = ToggleDarkMode();

export const returnFileSize = (number) => {
  let sizeText;
  const sizeInGB = (number / 1073741824).toFixed(1);
  if (number < 1024) {
    sizeText = `${number}bytes`;
  } else if (number >= 1024 && number < 1048576) {
    sizeText = `${(number / 1024).toFixed(1)}KB`;
  } else if (number >= 1048576 && number < 1073741824) {
    sizeText = `${(number / 1048576).toFixed(1)}MB`;
  } else if (number >= 1073741824) {
    sizeText = `${(number / 1073741824).toFixed(1)}GB`;
  }
  return { sizeText, sizeInGB };
};

export const parseDurationToSeconds = (durationInText) => {
  try {
    const parts = durationInText.split(':');
    let seconds = 0;

    // Extract milliseconds if present
    let milliseconds = 0;
    if (parts.length > 2 && parts[2].includes('.')) {
      const secAndMilli = parts[2].split('.');
      seconds += parseInt(secAndMilli[0], 10);
      milliseconds = Math.round(parseFloat(`0.${secAndMilli[1]}`) * 1000); // Round milliseconds
    } else if (parts.length > 2) {
      seconds += parseInt(parts[2], 10);
    }

    // Hours and minutes
    if (parts.length === 3 || parts.length === 4) {
      seconds += parseInt(parts[0], 10) * 3600;
      seconds += parseInt(parts[1], 10) * 60;
    } else if (parts.length === 2) {
      seconds += parseInt(parts[0], 10) * 60;
    }

    const totalSeconds = seconds + milliseconds / 1000;
    const roundedTotalSeconds = Math.round(totalSeconds * 1000) / 1000;
    return roundedTotalSeconds;
  } catch (error) {
    console.log('error', error);
    return 0;
  }
};

export const returnFileDuration = (durationInSec) => {
  const durationMS = durationInSec * 1000;
  const seconds = Math.floor((durationMS / 1000) % 60);
  const minutes = Math.floor((durationMS / 1000 / 60) % 60);
  const hours = Math.floor((durationMS / 1000 / 3600) % 24);

  let durationInText = '';
  if (hours > 0) durationInText += `${hours < 10 ? '0' : ''}${hours}:`;
  else durationInText += '00:';
  if (minutes > 0) durationInText += `${minutes < 10 ? '0' : ''}${minutes}:`;
  else durationInText += '00:';
  if (seconds > 0) durationInText += `${seconds < 10 ? '0' : ''}${seconds}`;
  else durationInText += '00';

  // const milliseconds = Math.floor((durationMS % 1000) / 10); // Extracting milliseconds
  // durationInText += `${milliseconds < 10 ? '0' : ''}${milliseconds}`;

  return { durationInText, durationMS };
};

export const getToken = () => {
  let token = null;
  try {
    token =
      localStorage.getItem('token') != null
        ? JSON.parse(localStorage.getItem('token'))
        : null;
  } catch (error) {
    console.error('>>>>: src/helpers/Utils.js  : getToken -> error', error);
    token = null;
  }
  return token;
};

export const setToken = (token) => {
  try {
    if (token) {
      localStorage.setItem('token', JSON.stringify(token));
    } else {
      localStorage.removeItem('token');
    }
  } catch (error) {
    console.error('>>>>: src/helpers/Utils.js : setToken -> error', error);
  }
};

export const refreshPage = () => {
  window.location.reload();
};

export const getStatusColor = (status) => {
  let statusColor = '';
  if (Number(status) === 1 || +status === 0) {
    statusColor = 'primary';
  } else if (Number(status) === 2) {
    statusColor = 'success';
  } else if (Number(status) === 3) {
    statusColor = 'secondary';
  } else if (Number(status) === 4) {
    statusColor = 'danger';
  } else if (Number(status) === 0) {
    statusColor = 'danger';
  }
  return statusColor;
};

export const getStatusText = ({ status, isStreaming = false }) => {
  let statusText = '';
  if (+status === 0) {
    return 'VALIDATING';
  }
  if (+status === 1) {
    if (isStreaming) {
      return 'OPTIMIZING';
    }
    return 'PROCESSING';
  }
  if (Number(status) === 2) {
    statusText = 'READY';
  } else if (Number(status) === 3) {
    statusText = 'DRAFT';
  } else if (Number(status) === 4) {
    statusText = 'FAILED';
  }
  return statusText;
};

export const getContentTypeExt = (contentType) => {
  return contentType?.split('/')[1];
};

export const errorLogger = (error) => {
  return console.error(error?.response?.data?.errorMsg || error);
};

/**
 * get space-name from one content id
 * @param {*} spaces array of spaces
 * @param {*} spaceId a specific space id
 * @returns the name of the space-id supplied
 */
export const getSpaceDataFromSpaceId = (spaces, spaceId) => {
  const spaceData = spaces.filter((x) => x.id === spaceId)[0];
  return { name: spaceData.name, description: spaceData.description };
};

/**
 * Reusable fuction to get current url in address bar
 * @requires history param and must be called from inside a functional component
 * @param {*} history history object from useHistory() hook
 * @returns location url
 */
export const getUrlFromHistory = (history) => {
  return history.location.pathname;
};

export const isVideo = (file) => {
  if (!file) {
    return;
  }
  if (file.type.includes('video')) {
    // eslint-disable-next-line consistent-return
    return true;
  }
};

export const isOggMp4Webm = (file) => {
  if (!file) {
    return;
  }
  if (
    file.type.includes('video/ogg') ||
    file.type.includes('audio/ogg') ||
    file.type.includes('video/mp4') ||
    file.type.includes('video/webm')
  ) {
    return true;
  }
};

export const isAudio = (file) => {
  if (!file) {
    return;
  }
  if (file.type.includes('audio') || file.type.includes('video')) {
    // eslint-disable-next-line consistent-return
    return true;
  }
};

export const handleSetupTenant = (data) => {
  if (!data) return;
  localStorage.setItem('selectedTenantId', data?.selectedTenantId);
  localStorage.setItem('defaultSpaceId', data?.selectedSpaceId);
};

export const getDefaultSpaceId = () => {
  return localStorage.getItem('defaultSpaceId');
};

export const getLastVisitedSpaceId = () => {
  const last = localStorage.getItem('lastVisitedSpace');
  if (last) {
    return last;
  }
  return getDefaultSpaceId();
};

export const setLastVisitedSpaceId = (spaceId) => {
  localStorage.setItem('lastVisitedSpace', spaceId);
};

export const setDefaultSpaceId = (selectedSpaceId) => {
  localStorage.setItem('defaultSpaceId', selectedSpaceId);
};

export const getSelectedSpaceFromTenant = () => {
  return JSON.parse(localStorage.getItem('tenantDetails')).data.selectedSpace;
};

export const getTenantDetails = () => {
  const data = localStorage.getItem('tenantDetails');
  if (data) {
    return JSON.parse(data).data;
  }
  return null;
};

export const getImageArray = (
  images,
  watermarkFileName,
  tags,
  category,
  newTitle,
  autoApprove
) => {
  const imgArray = [];
  images.forEach((image) => {
    imgArray.push({
      id: nanoid(),
      FileName: image.name,
      ContentType: image.type,
      Size: image.size,
      MediaType: 3,
      WatermarkFileName: watermarkFileName,
      Width: image.width,
      Height: image.height,
      tags,
      category,
      Title: newTitle,
      AutoApprove: autoApprove
    });
  });
  if (imgArray.length <= 5) return imgArray;
  return imgArray.splice(5);
};

export const uploadImagesToBlobStorage = async (
  imagesWithMeta,
  abortController,
  oldSasObj
) => {
  const getNewSasObj = (OldSasObj, imageObj) => {
    if (OldSasObj?.items?.length === 1) return OldSasObj;
    const itemsForImg = OldSasObj?.items.filter(
      (sasObj) => sasObj.contentId === imageObj.id
    );
    return {
      items: itemsForImg,
      sasUrl: OldSasObj?.sasUrl
    };
  };

  const res = Promise.all(
    imagesWithMeta.map(async (imageObj) => {
      const newSasObj = getNewSasObj(oldSasObj, imageObj);
      await uploadFileToBlob(
        imageObj.imageFile,
        () => {},
        abortController,
        newSasObj
      );
    })
  );
  return res;
};

// For Captions & Subtitles
export const uploadMultipleFilesToBlobStorage = async (
  filesWithMeta,
  abortController,
  oldSasObj
) => {
  const getNewSasObj = (OldSasObj, index) => {
    if (OldSasObj?.items?.length === 1) return OldSasObj;

    return {
      items: [OldSasObj?.items[index]],
      sasUrl: OldSasObj?.sasUrl
    };
  };

  const res = Promise.all(
    filesWithMeta.map(async (fileObj, index) => {
      const newSasObj = getNewSasObj(oldSasObj, index);
      await uploadFileToBlob(
        fileObj.file,
        () => {},
        abortController,
        newSasObj
      );
    })
  );
  return res;
};

export const getHeaders = async () => {
  const token = JSON.parse(localStorage.getItem('token'));
  const userId = JSON.parse(localStorage.getItem('tenantDetails'))?.data?.id;
  const Authorization = `Bearer ${token}`;
  const headers = {
    Authorization,
    uid: userId
  };
  return headers;
};

export const getCurrentUserDetails = () => {
  const tenantDetails = JSON.parse(localStorage.getItem('tenantDetails'));
  const email = tenantDetails?.data?.email;
  const fullName = tenantDetails?.data?.fullName;
  const id = tenantDetails?.data?.id;
  const regionIdentifier = tenantDetails?.data?.regionIdentifier;
  const countryCode = tenantDetails?.data?.selectedTenant?.countryCode;
  const location = tenantDetails?.data?.selectedTenant?.location;
  const subscriptionId = tenantDetails?.data?.subscription?.subscriptionId;
  const industry = tenantDetails?.data?.selectedTenant?.industry;
  const regionName = tenantDetails?.data?.selectedTenant?.regionName;
  const selectedTenantName = tenantDetails?.data?.selectedTenant?.name;
  const selectedTenantId = tenantDetails?.data?.selectedTenant?.id;
  const selectedSpaceId = tenantDetails?.data?.selectedSpaceId;
  const selectedSpaceName = tenantDetails?.data?.selectedSpace?.name;
  return {
    email,
    fullName,
    id,
    countryCode,
    location,
    industry,
    regionName,
    selectedTenantName,
    selectedTenantId,
    regionIdentifier,
    selectedSpaceId,
    subscriptionId,
    selectedSpaceName
  };
};

export const validateSpaceName = (value) => {
  let error;
  if (!value) {
    error = 'Space name cannot be empty or null';
  }
  return error;
};

export const handleAgeRestriction = (value) => {
  let error = '';
  if (+value < 6 || +value > 100) {
    error = 'Minimum age must be in between 6 and 100';
  }
  if (!Number.isInteger(+value)) {
    error = 'No Decimal value is allowed';
  }
  return error;
};

export const handleDomainError = (value) => {
  let error = '';
  if (!value) {
    error = 'Domains cannot be empty';
  }
  return error;
};

export const DomainRegex =
  /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

export const handleIpError = (value) => {
  let error = '';
  if (!value) {
    error = "IP's cannot be empty";
  }
  return error;
};

export const getBlobUrl = async (asset) => {
  if (asset) {
    try {
      const response = await fetch(asset);
      if (response.ok) {
        const blob = await response.blob();
        return URL.createObjectURL(blob);
        // eslint-disable-next-line no-else-return
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
    // eslint-disable-next-line no-else-return
  } else {
    return null;
  }
};

export const getBase64FromUrl = async (imageUrl) => {
  try {
    // Fetch the image content
    const response = await axios.get(imageUrl, { responseType: 'arraybuffer' });

    // Convert the image content to base64
    const base64Image = Buffer.from(response.data, 'binary').toString('base64');

    return base64Image;
  } catch (error) {
    return error.message;
  }
};

// User Types
export const getTeamUserRoleType = (userType) => {
  // eslint-disable-next-line use-isnan
  if (!userType || userType === NaN) {
    return 'Select Role';
  }
  switch (+userType) {
    case 0:
      return 'None';
    case 1:
      return 'Owner';
    case 2:
      return 'Billing Admin';
    case 3:
      return 'Admin';
    case 77:
      return 'Content Manager';
    case 555:
      return 'Contributor';
    case 999:
      return 'Viewer';
    default:
      return 'Unknown';
  }
};

export const getWorkflowType = (workflow) => {
  switch (+workflow) {
    case 0:
      return 'None';
    case 1:
      return <IntlMessages id="review.approved" />;
    case 2:
      return <IntlMessages id="review.commented" />;
    case 3:
      return <IntlMessages id="review.assigned" />;
    case 4:
      return <IntlMessages id="review.rejected" />;
    default:
      return 'None';
  }
};

export const getStylesForWorkflow = (workflow) => {
  switch (+workflow) {
    case 1:
      return 'Approved-workflow';
    case 2:
      return 'Reviewed-workflow';
    case 3:
      return 'Assigned-workflow';
    case 4:
      return 'Rejected-workflow';
    default:
      return null;
  }
};

export const RoleTypes = {
  NONE: 0,
  OWNER: 1,
  BILLING_ADMIN: 2,
  ADMIN: 3,
  CONTENT_MANAGER: 77,
  CONTRIBUTOR: 555,
  VIEWER: 999
};

export const removeHtmlTags = (string) => {
  return string.replace(/<\/?.+?>/gi, '');
};

export const IPRegex =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const validIp = (ip) => {
  return /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    ip
  );
};

const dateAsPerTimeZone = (dateToChange, timeZone) => {
  if (typeof dateToChange === 'string') {
    return new Date(
      new Date(dateToChange).toLocaleString('en-US', {
        timeZone
      })
    );
  }

  return new Date(
    dateToChange.toLocaleString('en-US', {
      timeZone
    })
  );
};

export const formatDate = (date, returnDay = false, timezone = null) => {
  if (!date) {
    return;
  }
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const newDate = dateAsPerTimeZone(
    date,
    timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const day = newDate.getDate();
  const monthIdx = newDate.getMonth();
  const month = months[monthIdx];
  const year = newDate.getFullYear();

  const opDate = `${day} ${month} ${year}`;
  if (returnDay) {
    return day;
  }
  return opDate;
};

export const formatTime = (date, returnDecimal = false, timezone = null) => {
  if (!date) {
    return;
  }
  const newDate = dateAsPerTimeZone(
    date,
    timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  let hours = newDate.getHours();
  let minutes = newDate.getMinutes();

  if (returnDecimal) {
    const decimalTime = +hours + +minutes / 60;
    return decimalTime.toFixed(2); // Return time with two decimal places
  }

  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12;

  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const time = `${hours}:${minutes} ${ampm}`;
  return time;
};

export const formatDateNTime = (date) => {
  if (date) {
    return `${formatDate(date)} ${formatTime(date)}`;
  }
  return '';
};

export const getLocalUserRoleType = () => {
  const roleType = localStorage.getItem('roleType') ?? 0;
  return roleType;
};

export const getTimeStamp = (stamp) => {
  const now = new Date();
  const month =
    now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1;
  const todate = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();
  const todatePlus = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();
  const date = `${now.getFullYear()}-${month}-${todate}`;
  const datePlus = `${now.getFullYear()}-${month}-${todatePlus}`;
  const minute =
    now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
  const hours = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours();
  const time = `${hours}:${minute}`;
  const timestampToday = `${date}T${time}`;
  const timestampTodayPlus = `${datePlus}T${time}`;
  const after1yearDate = `${now.getFullYear() + 1}-${month}-${todate}`;
  const after1yearTimeStamp = `${after1yearDate}T${time}`;
  if (stamp === 'min') {
    return timestampToday;
  }
  if (stamp === 'mid') {
    return timestampTodayPlus;
  }
  if (stamp === 'max') {
    return after1yearTimeStamp;
  }
  return timestampToday;
};

export const isMoreThanAYear = (date1, date2) => {
  date1 = new Date(date1);
  date2 = new Date(date2);
  const difference = Math.abs(date1.getTime() - date2.getTime());
  const millisecondsInYear = 1000 * 60 * 60 * 24 * 365;
  const yearsDifference = difference / millisecondsInYear;

  return yearsDifference >= 1;
};

export const getType = (type) => {
  switch (type) {
    case 1:
      return 'Video';
    case 2:
      return 'Audio';
    case 3:
      return 'Image';
    default:
      return 'Story';
  }
};

export const handleShowcaseShare = async (url, title, tenantName) => {
  try {
    url = (() => {
      const link = url.split('?');
      return link[0];
    })();
    if (navigator.share) {
      await navigator.share({
        title,
        text: `have a look at this content by ${tenantName}`,
        url
      });
    } else {
      console.error('browser not supported');
    }
  } catch (err) {
    console.error('error', err);
  }
};
export const handleOwnerShare = async (url, title) => {
  try {
    if (navigator.share) {
      await navigator.share({
        title,
        text: `have a look at this content creator`,
        url
      });
    } else {
      console.error('browser not supported');
    }
  } catch (err) {
    console.error('error', err);
  }
};

export function secondsToTimeString(sec) {
  if (sec === 0) return '0';

  const hrs = Math.floor(sec / 3600);
  // eslint-disable-next-line no-param-reassign
  sec -= hrs * 3600;

  const min = Math.floor(sec / 60);
  // eslint-disable-next-line no-param-reassign
  sec -= min * 60;

  let secStr = sec.toFixed(2);
  if (secStr.endsWith('.00')) {
    secStr = sec.toFixed(0);
  }

  const result =
    (hrs ? `${hrs} hr ` : '') +
    (min ? `${min} min ` : '') +
    (sec ? `${secStr} sec` : '');

  return result;
}

export const isUppercase = (str) => {
  return /^[A-Z]*$/.test(str);
};

export const handleQuotaDurationLimitToaster = ({
  subscriptionDetails,
  isDuration = false
}) => {
  const contentSize = (
    +subscriptionDetails?.contentSize /
    (1024 * 1024 * 1024)
  ).toFixed(1);

  const contentSizeLimit = (
    +subscriptionDetails?.contentSizeLimit /
    (1024 * 1024 * 1024)
  ).toFixed(1);

  const contentDuration = +subscriptionDetails.contentDuration;
  const totalDurationLimit = +subscriptionDetails?.totalDurationLimit;

  const message = isDuration
    ? `You have exceeded your quota duration limit of ${+totalDurationLimit} hour. Your current usage is ${secondsToTimeString(
        +contentDuration
      )}`
    : `You have exceeded your quota limit of ${contentSize} GB. Your current usage is ${contentSizeLimit} GB`;

  NotificationManager.error(
    message,
    <IntlMessages id="it.failed" />,
    8000,
    null,
    null,
    ''
  );
};

export const generateIntermediateDates = (startDate, endDate) => {
  const dates = [];
  const currentDate = new Date(startDate);
  const loopEndDate = new Date(endDate);

  while (currentDate <= loopEndDate) {
    dates.push(currentDate.toLocaleDateString('en-US'));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export const youtubeRegex =
  // eslint-disable-next-line no-useless-escape
  /^(https?:\/\/)?(www\.)?(youtube|youtu|youtube-nocookie)\.(com|be)\/(watch\?v=|embed\/|v\/|.+\?v=)?([^&=%\?]{11})/;

// eslint-disable-next-line no-useless-escape
export const vimeoRegex =
  /(?:www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|users\/\w+\/videos\/|video\/)(\d+)(?:|\/\?)?/i;

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const getDay = (date, justGetNumber = false) => {
  if (!date) {
    return;
  }
  const newDate = dateAsPerTimeZone(
    date,
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  if (justGetNumber) {
    return newDate.getDay();
  }
  return weekDays[newDate.getDay()];
};

export const handleContentApprovedMessage = (roleType) => {
  NotificationManager.warning(
    <IntlMessages
      id={+roleType === 1 ? 'content-owner-approved' : 'content-approved'}
    />
  );
};
export const handleStoryApprovedMessage = (roleType) => {
  NotificationManager.warning(
    <IntlMessages
      id={+roleType === 1 ? 'story-owner-approved' : 'story-approved'}
    />
  );
};

export const getPosition = (
  position,
  waterMarkOffset,
  height,
  width,
  canvas1
) => {
  let positionX = waterMarkOffset;
  let positionY = canvas1.height - height - waterMarkOffset;
  if (position === 'bottomright') {
    positionX = canvas1.width - width - waterMarkOffset;
    positionY = canvas1.height - height - waterMarkOffset;
  } else if (position === 'bottomleft') {
    positionX = waterMarkOffset;
    positionY = canvas1.height - height - waterMarkOffset;
  } else if (position === 'topright') {
    positionX = canvas1.width - width - waterMarkOffset;
    positionY = waterMarkOffset;
  } else if (position === 'topleft') {
    positionX = waterMarkOffset;
    positionY = waterMarkOffset;
  } else {
    positionX = canvas1.width - width - waterMarkOffset;
    positionY = canvas1.height - height - waterMarkOffset;
  }
  return {
    positionX,
    positionY
  };
};

export const handleCss = ({ color1, color2 }) => {
  const css = `.video-js .vjs-play-progress,.video-js  .vjs-volume-level {
    background-color: ${color2}
}
.video-js .vjs-loading-spinner {
    border-color: ${color2}
}
.video-js:hover .vjs-big-play-button,.video-js .vjs-big-play-button:focus,.video-js .vjs-big-play-button:active {
    background-color: ${color1}
}`;
  return css;
};

export const extractColors = (cssString) => {
  const colorRegex = /#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/g;
  const colors = cssString.match(colorRegex);
  return colors || [];
};

export const isYoutube = (contentSource) => {
  if (+contentSource === 3) {
    return true;
  }
  return false;
};
export const isVimeo = (contentSource) => {
  if (+contentSource === 4) {
    return true;
  }
  return false;
};

export const isYoutubeORVimeoSource = (contentSource) => {
  if (isYoutube(+contentSource) || isVimeo(+contentSource)) {
    return true;
  }
  return false;
};

export const getTextColorFromBgColor = (color) => {
  const isColorDark = colord(color).isDark();
  if (isColorDark) {
    return '#fff';
  }
  return '#000';
};

export const handleDownload = (file) => {
  const link = document.createElement('a');
  link.download = true;
  link.title = file.name;
  link.href = URL.createObjectURL(file);
  link.click();
};

export const resizeImageFromFile = async ({
  imageFile,
  width,
  height,
  isUrl
}) => {
  console.log({
    imageFile,
    width,
    height,
    isUrl
  });
  try {
    const resizedBlob = await new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob(resolve, 'image/jpeg', 1);
      };
      img.src = isUrl ? imageFile : URL.createObjectURL(imageFile);
      img.crossOrigin = 'Anonymous';
    });

    const downloadableFile = new File([resizedBlob], `${Date.now()}.jpg`, {
      type: 'image/jpeg'
    });

    // const link = document.createElement('a');
    // link.download = true;
    // link.title = downloadableFile.name;
    // link.href = URL.createObjectURL(downloadableFile);
    // console.log('downloading after resizing image');
    // link.click();
    console.log('resized image', downloadableFile);
    return downloadableFile;
  } catch (error) {
    console.error('Error resizing image:', error);
    return null;
  }
};

export const base64toGIF = (base64String) => {
  // Remove the data URI prefix and get just the base64-encoded content
  const base64Content = base64String.split(';base64,').pop();
  const byteCharacters = atob(base64Content);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: 'image/gif' });
  const file = new File([blob], `${Date.now()}.gif`, { type: 'image/gif' });
  return file;
};

export const isTimeDifferenceGreaterThanOneHour = (date1, date2) => {
  const date1Ms = date1.getTime();
  const date2Ms = date2.getTime();
  const differenceMs = Math.abs(date1Ms - date2Ms);
  const differenceHours = differenceMs / (1000 * 60 * 60);
  return differenceHours > 1;
};

export const webvttToJSON = (webvtt) => {
  const lines = webvtt.split('WEBVTT\n\n')[1];
  const inputArray = lines.split('\n');
  const result = [];
  for (let i = 0; i < inputArray.length; i += 4) {
    const title = inputArray[i + 2];
    const startTime = parseFloat(inputArray[i + 1].split(' ')[0]);
    const timecode = `${inputArray[i + 1].split(' ')[0].replace('.', ':')}`;
    result.push({
      title: title,
      startTime: startTime,
      timecode: timecode
    });
  }
  return result;
};

export const timeToUTCMillisConvert = (Dtime, tzone) => {
  const date = Dtime.split('T')[0].split('-');
  const time = Dtime.split('T')[1].split(':');
  const UTCMillis = DateTime.fromObject(
    {
      year: parseInt(date[0]),
      month: parseInt(date[1]),
      day: parseInt(date[2]),
      hour: parseInt(time[0]),
      minute: parseInt(time[1])
    },
    { zone: tzone }
  )
    .toUTC()
    .toMillis();
  return UTCMillis;
};

export const UTCMillisToDateTime = (UTCMillis, tzone) => {
  const dateTime = DateTime.fromMillis(UTCMillis, { zone: tzone });
  const Date = dateTime.toFormat('yyyy-MM-dd');
  const Time = dateTime.toFormat('HH:mm');
  const timestamp = `${Date}T${Time}`;
  return timestamp;
};

export const getRandomChars = (len) => {
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_';
  let result = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

const isContentProcessing = (isRemote = false, status) => {
  if ((+status === 0 && isRemote) || +status === 1) {
    return true;
  }
  return false;
};

export const ifProcessingReturnContentOrElseNull = (
  content,
  returnBoolean = false,
  isList
) => {
  const isProcessing = isContentProcessing(
    +content.contentSource === 2,
    +content.status
  );

  const isReadyButSynching =
    +content.status === 2 && content.isCdnCopyInProgress === true;
  const isTimeDiffGreaterThnHour = isTimeDifferenceGreaterThanOneHour(
    new Date(content?.lastUpdatedDateTime),
    new Date()
  );
  const flag = isList ? isTimeDiffGreaterThnHour : !isTimeDiffGreaterThnHour;
  if ((isProcessing || isReadyButSynching) && flag) {
    if (returnBoolean) {
      return true;
    }
    return content;
  }
  if (returnBoolean) {
    return false;
  }
  return null;
};

export const arrayToWebVTT = (array) => {
  let webvttText = 'WEBVTT\n\n';

  array.forEach((item, index) => {
    webvttText += `${index + 1}\n`;
    webvttText += `${item.timecode} --> ${item.timecode}\n`;
    webvttText += `${item.title}\n\n`;
  });

  return webvttText;
};

export const getSpacesNames = (array, wrapInvertedCommas = false) => {
  if (!wrapInvertedCommas) {
    if (array) {
      if (array.length === 1) {
        return array[0].name;
      }
      if (array.length > 1) {
        const names = array.map((m) => m.name);
        return `${names.slice(0, -1).join(', ')} and ${names.slice(-1)}`;
      }
    }
    return '';
  }
  if (array) {
    if (array.length === 1) {
      return `"${array[0].name}"`;
    }
    if (array.length > 1) {
      const names = array.map((m) => `"${m.name}"`);
      return `${names.slice(0, -1).join(', ')} and ${names.slice(-1)}`;
    }
  }
  return '';
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const getDaysUntillExpiry = (expiryDate) => {
  const currentDateTime = new Date();
  const expiryDateTime = new Date(expiryDate);
  const timeDifference = expiryDateTime - currentDateTime;
  const daysUntilExpiry = (() => {
    const expiry = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) - 1;
    const subscriptionExpired = currentDateTime > expiryDateTime;
    // console.log({
    //   currentDateTime,
    //   expiryDateTime,
    //   timeDifference,
    //   expiry,
    //   subscriptionExpired
    // });
    if (expiry === 0) {
      if (!subscriptionExpired) {
        return 1;
      }
      return 0;
    }
    return expiry;
  })();
  return daysUntilExpiry;
};

export const validateStoryTitle = (title) => {
  if (!title || title.trim().length === 0) {
    return 'title.empty-error';
  }
  if (title.trim().length < 2) {
    return 'title.atleast2';
  }
  if (title.length > 50) {
    return 'story.title-less';
  }
  return '';
};

export const getExpiryMessage = (daysUntillExpiry) => {
  if (daysUntillExpiry < 0) {
    return <IntlMessages id="subscription-expired" />;
  }
  if (daysUntillExpiry === 0) {
    return <IntlMessages id="subscription-zero" />;
  }
  if (daysUntillExpiry === 1) {
    return <IntlMessages id="subscription-one" />;
  }
  if (daysUntillExpiry === 2) {
    return <IntlMessages id="subscription-two" />;
  }
  if (daysUntillExpiry === 3) {
    return <IntlMessages id="subscription-three" />;
  }
  if (daysUntillExpiry === 4) {
    return <IntlMessages id="subscription-four" />;
  }
  if (daysUntillExpiry === 5) {
    return <IntlMessages id="subscription-five" />;
  }
  if (daysUntillExpiry === 6) {
    return <IntlMessages id="subscription-six" />;
  }
  if (daysUntillExpiry === 7) {
    return <IntlMessages id="subscription-seven" />;
  }
  return '';
};

export const copyStringToClipboard = (str) => {
  // Create new element
  const el = document.createElement('textarea');
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand('copy');
  // Remove temporary element
  document.body.removeChild(el);
};

export const isInvitedUser = () => {
  const invitationId = localStorage.getItem('invitationId');
  if (!invitationId) {
    return false;
  }
  return invitationId !== 'null' || invitationId !== 'undefined';
};

export const getMediaType = (mimeType) => {
  const mimeLower = mimeType.toLowerCase();

  if (mimeLower.startsWith('video/')) {
    return 1;
  } else if (mimeLower.startsWith('audio/')) {
    return 2;
  } else if (mimeLower.startsWith('image/')) {
    return 3;
  }
};

export const isSieveProcessing = (status) => {
  return (
    status === 'processing' || status === 'uploading' || status === 'queued'
  );
};

export const isDownloadAllowed = ({
  roleType,
  isYtVimeoVideo,
  status,
  allowDownload
}) => {
  const isAllowed =
    roleType <= RoleTypes.CONTENT_MANAGER &&
    !isYtVimeoVideo &&
    allowDownload &&
    (status === 2 || status === 3);

  console.log({
    isAllowed,
    roleType,
    isYtVimeoVideo,
    allowDownload,
    status
  });

  return isAllowed;
};

export const getSHA256Hash = async (inputText) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(inputText);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};
