/* eslint-disable no-empty */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const service = 'public';

export const getPublicPreview = async (
  spaceId,
  contentId,
  payload,
  isMulti
) => {
  let response = { isError: true, data: null };

  try {
    const { data } = await axios.post(
      `${baseUrl}/${service}/${spaceId}/get-preview-by-content-id/${contentId}`,
      payload,
      {
        headers: {
          'x-multi': isMulti
        }
      }
    );
    response = data;
  } catch (e) {
    console.error(e);
  }
  return response;
};

export const getPublicStreamingToken = async (
  mediaType,
  contentId,
  playerId,
  storyId
) => {
  let response = { isError: true, data: null };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/${service}/get-streaming-token?mediaType=${mediaType}&contentId=${contentId}&playerId=${playerId}&storyId=${storyId}`
    );
    response = res;
  } catch (e) {
    console.error(e);
    return response;
  }
  return response;
};

export const getPublicShowcases = async (payload) => {
  let response = { isError: true, data: null };
  try {
    const { data } = await axios.get(`${baseUrl}/${service}/get-showcases`, {
      params: payload
    });
    response = data;
  } catch (e) {
    console.error(e);
  }
  return response;
};

export const getPublicSingleShowcase = async ({
  showcaseId,
  tenantId,
  userId
}) => {
  let response = { isError: true, data: null };
  try {
    const { data } = await axios.get(
      `${baseUrl}/${service}/internal/get-showcase-by-id/${showcaseId}`,
      {
        headers: {
          'x-showcase-tenant-id': tenantId,
          'x-caller-id': userId
        }
      }
    );
    response = data;
  } catch (e) {
    console.error(e);
  }
  return response;
};

export const updateShowcaseClicks = async (payload) => {
  let response = { isError: true, data: null };
  try {
    const { data } = await axios.post(
      `${baseUrl}/${service}/update-showcase-clicks`,
      payload
    );
    response = data;
  } catch (e) {
    console.error(e);
  }
  return response;
};

export const updateShowcaseImpression = async (payload) => {
  let response = { isError: true, data: null };
  try {
    const { data } = await axios.post(
      `${baseUrl}/${service}/update-showcase-impressions`,
      payload
    );
    response = data;
  } catch (e) {
    console.error(e);
  }
  return response;
};

export const verifyRecaptchaToken = async (payload) => {
  let response = { isError: true, data: null };
  try {
    const { data } = await axios.post(
      `${baseUrl}/${service}/verify-recaptcha`,
      payload
    );
    response = data;
  } catch (e) {
    console.error(e);
  }
  return response;
};

export const updateLastViewdTT = async (payload, tenantId, userTenantId) => {
  let response = { isError: true, data: null };
  try {
    const { data } = await axios.put(
      `${baseUrl}/${service}/internal/update-showcase-last-viewed-timestamp`,
      {
        payload,
        tenantId
      },
      {
        headers: {
          'x-caller-id': userTenantId
        }
      }
    );
    response = data;
  } catch (e) {
    console.error(e);
  }
  return response;
};

export const addUserSession = async (userId, email) => {
  let response = { isError: true, data: null };
  try {
    const { data } = await axios.post(
      `${baseUrl}/${service}/add-user-session`,
      {
        userId,
        email
      }
    );
    response = data;
  } catch (e) {
    console.error(e);
  }
  console.log('response', response);
  return response;
};

export const deleteSession = async (userId) => {
  let response = { isError: true, data: null };
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_SERVER_BASE_URL}/${service}/delete-user-session/${userId}`
    );
    response = data;
  } catch (e) {
    console.error(e);
  }
  return response;
};

export const isEmailDisposible = async (email) => {
  let response = { isError: true, data: null };
  try {
    const { data } = await axios.get(
      `${baseUrl}/${service}/is-desposible-email/${email}`
    );
    response = data;
  } catch (e) {
    console.error(e);
  }
  return response;
};

export const unlockImage = async (accessCode, contentId) => {
  let response = { isError: true, data: null };
  try {
    const { data } = await axios.post(`${baseUrl}/${service}/unlock-image`, {
      password: accessCode,
      contentId
    });
    response = data;
  } catch (e) {
    console.error(e);
  }
  return response;
};

export const createAccessToken = async (payload) => {
  let response = { isError: true, data: null };
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/${service}/create-access-token`,
      payload
    );
    response = data;
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const validateAccessToken = async (payload) => {
  let response = { isError: true, data: null };
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/${service}/validate-access-token`,
      payload
    );
    // {
    //   "tenantId":"",
    //   "accessCodeStatus": 1 or 2
    //   }
    // MailSent=1,
    // HasAccount=2
    response = data;
  } catch (error) {
    console.error(error);
  }
  return response;
};
