/* eslint-disable import/prefer-default-export */
/* eslint-disable no-else-return */
import React from 'react';
import IntlMessages from './IntlMessages';

export const getErrorMessage = (error) => {
  const data = {
    url: error?.config?.url,
    method: error?.config?.method,
    code: error?.response?.status,
    message: error?.response?.data?.data?.message,
    payload:
      error.response.config.data && JSON.parse(error.response.config.data)
  };

  console.log({
    data,
    error
  });

  if (data.code === 429) {
    <IntlMessages id="err-something-went-wrong-too-many-requests" />;
  } else if (
    data.url?.includes('/space/create-space') &&
    data.method === 'post'
  ) {
    if (error.response.data.data.message === 'SPACE_LIMIT_EXCEEDED') {
      return <IntlMessages id="err-space-limit-exceeded" />;
    }
    return <IntlMessages id="err-space-creation-failed" />;
  } else if (
    data.url?.includes('/space/create-sas-url') &&
    data.method === 'post'
  ) {
    return <IntlMessages id="err-sas-url-creation-failed" />;
  } else if (
    data.url?.includes('/space/thumbnail-create-sas-url') &&
    data.method === 'post' &&
    data.code !== 403
  ) {
    return <IntlMessages id="err-could-not-start-thumbnail-upload" />;
  } else if (error.response.data.data.message === 'PLAYER_LIMIT_REACHED') {
    return <IntlMessages id="err-player-limit-exceeded" />;
  } else if (
    error.response.data.data.message === 'USER_DOES_NOT_HAVE_ACCESS_TO_SPACE'
  ) {
    return <IntlMessages id="user-dont-have-space-access" />;
  } else if (
    data.url?.includes('/players/create-player') &&
    data.method === 'post' &&
    (data.code === 404 || data.code === 400) &&
    error.response.data.data.message !== 'PLAYER_LIMIT_REACHED'
  ) {
    return <IntlMessages id="err-player-creation-failed" />;
  } else if (
    data.url?.includes('/players/update-player') &&
    data.method === 'put' &&
    (data.code === 404 || data.code === 400)
  ) {
    return (
      <div>
        <IntlMessages id="err-player-creation-failed" />
        {error.response.data?.errorMsg}
      </div>
    );
  } else if (
    data.url?.includes('/players/delete-player') &&
    data.method === 'put' &&
    (data.code === 404 || data.code === 400) &&
    error.response.data?.errorMsg !== 'PLAYER_IS_ASSOCIATED_WITH_SHARE'
  ) {
    return (
      <div>
        <IntlMessages id="err-player-deletion-failed" />
        {error.response.data?.errorMsg}
      </div>
    );
  } else if (
    data.url?.includes('/players/delete-player') &&
    data.method === 'put' &&
    (data.code === 404 || data.code === 400)
  ) {
    return <IntlMessages id="err-player-deletion-failed-due-to-share" />;
  } else if (
    error.response.data.data.message === 'PLAYER_WITH_SAME_TITLE_ALREADY_EXIST'
  ) {
    return <IntlMessages id="err-player-name-exists" />;
  } else if (error.response.data.data.message === 'CONTENT_DOES_NOT_EXIST') {
    return <IntlMessages id="content-try-visit" />;
  } else if (
    error.response.data.data.message === 'Content_WITH_SAME_TITLE_ALREADY_EXIST'
  ) {
    return <IntlMessages id="err-content-name-exists" />;
  } else if (
    error.response.data.data.message === 'STORY_WITH_SAME_NAME_ALREADY_EXIST'
  ) {
    return <IntlMessages id="err-story-name-exists" />;
  } else if (
    error.response.data.data.message === 'SPACE_WITH_SAME_NAME_ALREADY_EXIST'
  ) {
    return <IntlMessages id="err-space-name-exists" />;
  } else if (error.response.data.data.message === 'EXCEEDING_STORY_LIMIT') {
    return <IntlMessages id="err-exceeding-story-limit" />;
  } else if (error.response.data.data.message === 'SHOWCASE_LIMIT_REACHED') {
    return <IntlMessages id="err-showcase-limit-reached" />;
  } else if (data.message === 'BRAND_IS_USING_BY_PLAYER') {
    return <IntlMessages id="brand-player-using" />;
  } else if (data.message === 'ONLY_CUSTOM_THUMBNAIL_CAN_BE_REMOVED') {
    return <IntlMessages id="default-plyr-thumb-removed-err" />;
  } else if (data.message === 'SHARE_DOES_NOT_EXIST') {
    return <IntlMessages id="share.expired" />;
  } else if (typeof error.response.data.data.message === 'string') {
    return error.response.data.data.message;
  }

  return <IntlMessages id="err-something-went-wrong" />;
};
