/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
// eslint-disable import/prefer-default-export
import axios from 'axios';
import { handleSetupTenant, getHeaders } from 'helpers/Utils';

const service = 'user';

export const handleCreateTenant = async (payload) => {
  console.log('called ---->handleCreateTenant');
  const { invitationId } = payload;
  const url = invitationId
    ? `create-tenant?invitationId=${invitationId}`
    : `create-tenant`;

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/${service}/${url}`,
      payload
    );

    const response = res?.data;

    console.log('response', response);
    console.log('res?.data', res?.data);

    if (
      res.data.status === 403 &&
      res.data.message === 'TEAM_MEMBER_LIMIT_EXCEEDED'
    ) {
      return { data: null, error: res.data.message };
    }

    if (response && response?.data && !response?.data?.message && !res?.data?.isError ) {
      localStorage.setItem('tenantID', response?.data?.selectedTenantId);
      return { data: response?.data, error: null };
    }
    return {
      data: null,
      error: response?.data?.message ?? 'Something went wrong!'
    };
  } catch (e) {
    console.error(e);
    if (e.response?.status === 400) {
      return {
        data: null,
        error: 'INVALID_ACCESS_CODE'
      };
    } else {
      return { data: null, error: e.message };
    }
  }
};

export const fetchUser = async (payload) => {
  console.log('called ---->fetchUser');
  try {
    if (payload) {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/${service}/on-join-tenant/${payload}`
      );
      if (
        res.data.data.message === 'TEAM_MEMBER_LIMIT_EXCEEDED' &&
        res.data.data.status === 403
      ) {
        localStorage.setItem('team-member-quota', 'TEAM_MEMBER_LIMIT_EXCEEDED');
        return {
          data: null,
          errorType: 'quota-member-limit',
          message: 'TEAM_MEMBER_LIMIT_EXCEEDED'
        };
      }
      if (res?.data?.isError) {
        return {
          data: null,
          errorType: 'Invalid-Invitation-link',
          message:
            'The invitation link is invalid or expired. Please contact your administrator.'
        };
      }
    }
    const usersInfo = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/${service}/get-user`
    );
    if (
      usersInfo?.data?.data?.message === 'USER_NOT_EXIST' ||
      typeof usersInfo === 'undefined'
    ) {
      return {
        data: null,
        errorType: usersInfo?.data?.data?.message ?? 'user-not-registered',
        message:
          'Account Information Not Found. Please Complete Registration Process.'
      };
    }
    if (
      usersInfo.data.data.message ===
      'ONLY_USER_BELONGS_TO_TENANT_CAN_GET_TENANT' &&
      usersInfo.data.isError
    ) {
      return {
        data: null,
        errorType: 'account-disabled',
        message: 'Account information not found, or it is disabled.'
      };
    }
    const data = {
      data: usersInfo?.data?.data,
      isError: usersInfo?.data?.isError
    };
    if (data) {
      localStorage.setItem('tenantDetails', JSON.stringify(data));
      handleSetupTenant(data.data);
      return { data };
    }
  } catch (e) {
    console.error(e);
    if (e.response?.status === 404) {
      return {
        data: null,
        errorType: 'user-not-registered',
        message:
          'Account Information Not Found. Please Complete Registration Process.'
      };
    }
  }

  return {
    data: null,
    errorType: null,
    message: 'Something Went Wrong!'
  };
};

// eslint-disable-next-line consistent-return
export const getCurrentTenant = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/${service}/get-current-tenant`
    );
    return data;
  } catch (e) {
    console.error(e);
    if (e.response?.status === 404) {
      return {
        data: null,
        errorType: 'account-not-exists',
        message: 'Account Information not found!!'
      };
    }
  }
};

export async function handleCreatePlayer(playerObj) {
  try {
    const headers = await getHeaders();
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/players/create-player`,
      playerObj,
      {
        headers
      }
    );

    return { data };
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function handleSearchPlayers(payload) {
  try {
    const headers = await getHeaders();
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/players/search-players`,
      payload,
      {
        headers
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return null;
  }
}
export async function getAllPlayers(maxResultCount, skipCount) {
  try {
    const headers = await getHeaders();
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/players/get-players`,
      {
        headers,
        params: {
          maxResultCount,
          skipCount
        }
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function handleUpdatePlayer(payload) {
  try {
    const body = {
      ...payload
    };
    const headers = await getHeaders();
    const { data } = await axios.put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/players/update-player`,
      body,
      {
        headers
      }
    );

    return { data };
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function handleDeletePlayer(payload) {
  try {
    const body = {
      ...payload
    };
    const headers = await getHeaders();
    const { data } = await axios.put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/players/delete-player`,
      body,
      {
        headers
      }
    );

    return data;
  } catch (err) {
    console.error(err);
    return { isError: true };
  }
}

export async function handelProfileUpdate(payload) {
  try {
    const headers = await getHeaders();
    const result = await axios.put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/user/update-profile`,
      payload,
      {
        headers
      }
    );
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export const fetchSystemNotifications = async (payload) => {
  try {
    const skipCount = payload?.skipCount ?? 0;
    const maxResultCount = payload?.maxResultCount ?? 2;
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/notifications/system?maxResultCount=${maxResultCount}&skipCount=${skipCount}`
    );
    return data;
  } catch (error) {
    // console.error(error);
    return [];
  }
};

export const getStreamToken = async (
  mediaType,
  contentId,
  playerId,
  storyId
) => {
  let response = { isError: true, data: null };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/validations/get-streaming-token?mediaType=${mediaType}&contentId=${contentId}&playerId=${playerId}&storyId=${storyId}`
    );
    response = res;
  } catch (e) {
    console.error(e);
    return response;
  }
  return response;
};

export const getTenants = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/user/get-user-tenant`
    );
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const setDefaultTenantApi = async (tenantID) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/user/set-default-tenant/${tenantID}`
    );
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUserRole = async (spaceID) => {
  try {
    if (!spaceID) {
      localStorage.removeItem('roleType');
    }
    const { data } = await axios.get(
      spaceID
        ? `${process.env.REACT_APP_SERVER_BASE_URL}/mgmtsvc/get-user-role?spaceId=${spaceID}`
        : `${process.env.REACT_APP_SERVER_BASE_URL}/mgmtsvc/get-user-role`
    );

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createTenantProfileSasUrl = async (body) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/${service}/create-tenant-profile-sas-url`,
      body
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const isUserNameAvailable = async (username) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/${service}/is-username-available/${username}`
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getTenantInfo = async (
  tenantId,
  isLoggedIn,
  IncludePlayerInfo
) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/${!isLoggedIn ? 'public' : service
      }/get-tenant-profile/${tenantId}`,
      {
        params: {
          IncludePlayerInfo
        }
      }
    );

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const updateTenantProfile = async (body) => {
  try {
    const result = await axios.put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/${service}/update-tenant-profile`,
      body
    );
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const spaceSummaryRefresh = async (spaceId) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/${service}/space-summary-refresh/${spaceId}`
    );
    return data ?? null;
  } catch (error) {
    console.error(error);
  }
};

